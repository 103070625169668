var exports = {};

// https://d3js.org/d3-array/ Version 1.2.1. Copyright 2017 Mike Bostock.
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var ascending = function (a, b) {
    return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
  };

  var bisector = function (compare) {
    if (compare.length === 1) compare = ascendingComparator(compare);
    return {
      left: function (a, x, lo, hi) {
        if (lo == null) lo = 0;
        if (hi == null) hi = a.length;

        while (lo < hi) {
          var mid = lo + hi >>> 1;
          if (compare(a[mid], x) < 0) lo = mid + 1;else hi = mid;
        }

        return lo;
      },
      right: function (a, x, lo, hi) {
        if (lo == null) lo = 0;
        if (hi == null) hi = a.length;

        while (lo < hi) {
          var mid = lo + hi >>> 1;
          if (compare(a[mid], x) > 0) hi = mid;else lo = mid + 1;
        }

        return lo;
      }
    };
  };

  function ascendingComparator(f) {
    return function (d, x) {
      return ascending(f(d), x);
    };
  }

  var ascendingBisect = bisector(ascending);
  var bisectRight = ascendingBisect.right;
  var bisectLeft = ascendingBisect.left;

  var pairs = function (array, f) {
    if (f == null) f = pair;
    var i = 0,
        n = array.length - 1,
        p = array[0],
        pairs = new Array(n < 0 ? 0 : n);

    while (i < n) pairs[i] = f(p, p = array[++i]);

    return pairs;
  };

  function pair(a, b) {
    return [a, b];
  }

  var cross = function (values0, values1, reduce) {
    var n0 = values0.length,
        n1 = values1.length,
        values = new Array(n0 * n1),
        i0,
        i1,
        i,
        value0;
    if (reduce == null) reduce = pair;

    for (i0 = i = 0; i0 < n0; ++i0) {
      for (value0 = values0[i0], i1 = 0; i1 < n1; ++i1, ++i) {
        values[i] = reduce(value0, values1[i1]);
      }
    }

    return values;
  };

  var descending = function (a, b) {
    return b < a ? -1 : b > a ? 1 : b >= a ? 0 : NaN;
  };

  var number = function (x) {
    return x === null ? NaN : +x;
  };

  var variance = function (values, valueof) {
    var n = values.length,
        m = 0,
        i = -1,
        mean = 0,
        value,
        delta,
        sum = 0;

    if (valueof == null) {
      while (++i < n) {
        if (!isNaN(value = number(values[i]))) {
          delta = value - mean;
          mean += delta / ++m;
          sum += delta * (value - mean);
        }
      }
    } else {
      while (++i < n) {
        if (!isNaN(value = number(valueof(values[i], i, values)))) {
          delta = value - mean;
          mean += delta / ++m;
          sum += delta * (value - mean);
        }
      }
    }

    if (m > 1) return sum / (m - 1);
  };

  var deviation = function (array, f) {
    var v = variance(array, f);
    return v ? Math.sqrt(v) : v;
  };

  var extent = function (values, valueof) {
    var n = values.length,
        i = -1,
        value,
        min,
        max;

    if (valueof == null) {
      while (++i < n) {
        // Find the first comparable value.
        if ((value = values[i]) != null && value >= value) {
          min = max = value;

          while (++i < n) {
            // Compare the remaining values.
            if ((value = values[i]) != null) {
              if (min > value) min = value;
              if (max < value) max = value;
            }
          }
        }
      }
    } else {
      while (++i < n) {
        // Find the first comparable value.
        if ((value = valueof(values[i], i, values)) != null && value >= value) {
          min = max = value;

          while (++i < n) {
            // Compare the remaining values.
            if ((value = valueof(values[i], i, values)) != null) {
              if (min > value) min = value;
              if (max < value) max = value;
            }
          }
        }
      }
    }

    return [min, max];
  };

  var array = Array.prototype;
  var slice = array.slice;
  var map = array.map;

  var constant = function (x) {
    return function () {
      return x;
    };
  };

  var identity = function (x) {
    return x;
  };

  var range = function (start, stop, step) {
    start = +start, stop = +stop, step = (n = arguments.length) < 2 ? (stop = start, start = 0, 1) : n < 3 ? 1 : +step;
    var i = -1,
        n = Math.max(0, Math.ceil((stop - start) / step)) | 0,
        range = new Array(n);

    while (++i < n) {
      range[i] = start + i * step;
    }

    return range;
  };

  var e10 = Math.sqrt(50);
  var e5 = Math.sqrt(10);
  var e2 = Math.sqrt(2);

  var ticks = function (start, stop, count) {
    var reverse,
        i = -1,
        n,
        ticks,
        step;
    stop = +stop, start = +start, count = +count;
    if (start === stop && count > 0) return [start];
    if (reverse = stop < start) n = start, start = stop, stop = n;
    if ((step = tickIncrement(start, stop, count)) === 0 || !isFinite(step)) return [];

    if (step > 0) {
      start = Math.ceil(start / step);
      stop = Math.floor(stop / step);
      ticks = new Array(n = Math.ceil(stop - start + 1));

      while (++i < n) ticks[i] = (start + i) * step;
    } else {
      start = Math.floor(start * step);
      stop = Math.ceil(stop * step);
      ticks = new Array(n = Math.ceil(start - stop + 1));

      while (++i < n) ticks[i] = (start - i) / step;
    }

    if (reverse) ticks.reverse();
    return ticks;
  };

  function tickIncrement(start, stop, count) {
    var step = (stop - start) / Math.max(0, count),
        power = Math.floor(Math.log(step) / Math.LN10),
        error = step / Math.pow(10, power);
    return power >= 0 ? (error >= e10 ? 10 : error >= e5 ? 5 : error >= e2 ? 2 : 1) * Math.pow(10, power) : -Math.pow(10, -power) / (error >= e10 ? 10 : error >= e5 ? 5 : error >= e2 ? 2 : 1);
  }

  function tickStep(start, stop, count) {
    var step0 = Math.abs(stop - start) / Math.max(0, count),
        step1 = Math.pow(10, Math.floor(Math.log(step0) / Math.LN10)),
        error = step0 / step1;
    if (error >= e10) step1 *= 10;else if (error >= e5) step1 *= 5;else if (error >= e2) step1 *= 2;
    return stop < start ? -step1 : step1;
  }

  var sturges = function (values) {
    return Math.ceil(Math.log(values.length) / Math.LN2) + 1;
  };

  var histogram = function () {
    var value = identity,
        domain = extent,
        threshold = sturges;

    function histogram(data) {
      var i,
          n = data.length,
          x,
          values = new Array(n);

      for (i = 0; i < n; ++i) {
        values[i] = value(data[i], i, data);
      }

      var xz = domain(values),
          x0 = xz[0],
          x1 = xz[1],
          tz = threshold(values, x0, x1); // Convert number of thresholds into uniform thresholds.

      if (!Array.isArray(tz)) {
        tz = tickStep(x0, x1, tz);
        tz = range(Math.ceil(x0 / tz) * tz, Math.floor(x1 / tz) * tz, tz); // exclusive
      } // Remove any thresholds outside the domain.


      var m = tz.length;

      while (tz[0] <= x0) tz.shift(), --m;

      while (tz[m - 1] > x1) tz.pop(), --m;

      var bins = new Array(m + 1),
          bin; // Initialize bins.

      for (i = 0; i <= m; ++i) {
        bin = bins[i] = [];
        bin.x0 = i > 0 ? tz[i - 1] : x0;
        bin.x1 = i < m ? tz[i] : x1;
      } // Assign data to bins by value, ignoring any outside the domain.


      for (i = 0; i < n; ++i) {
        x = values[i];

        if (x0 <= x && x <= x1) {
          bins[bisectRight(tz, x, 0, m)].push(data[i]);
        }
      }

      return bins;
    }

    histogram.value = function (_) {
      return arguments.length ? (value = typeof _ === "function" ? _ : constant(_), histogram) : value;
    };

    histogram.domain = function (_) {
      return arguments.length ? (domain = typeof _ === "function" ? _ : constant([_[0], _[1]]), histogram) : domain;
    };

    histogram.thresholds = function (_) {
      return arguments.length ? (threshold = typeof _ === "function" ? _ : Array.isArray(_) ? constant(slice.call(_)) : constant(_), histogram) : threshold;
    };

    return histogram;
  };

  var quantile = function (values, p, valueof) {
    if (valueof == null) valueof = number;
    if (!(n = values.length)) return;
    if ((p = +p) <= 0 || n < 2) return +valueof(values[0], 0, values);
    if (p >= 1) return +valueof(values[n - 1], n - 1, values);
    var n,
        i = (n - 1) * p,
        i0 = Math.floor(i),
        value0 = +valueof(values[i0], i0, values),
        value1 = +valueof(values[i0 + 1], i0 + 1, values);
    return value0 + (value1 - value0) * (i - i0);
  };

  var freedmanDiaconis = function (values, min, max) {
    values = map.call(values, number).sort(ascending);
    return Math.ceil((max - min) / (2 * (quantile(values, 0.75) - quantile(values, 0.25)) * Math.pow(values.length, -1 / 3)));
  };

  var scott = function (values, min, max) {
    return Math.ceil((max - min) / (3.5 * deviation(values) * Math.pow(values.length, -1 / 3)));
  };

  var max = function (values, valueof) {
    var n = values.length,
        i = -1,
        value,
        max;

    if (valueof == null) {
      while (++i < n) {
        // Find the first comparable value.
        if ((value = values[i]) != null && value >= value) {
          max = value;

          while (++i < n) {
            // Compare the remaining values.
            if ((value = values[i]) != null && value > max) {
              max = value;
            }
          }
        }
      }
    } else {
      while (++i < n) {
        // Find the first comparable value.
        if ((value = valueof(values[i], i, values)) != null && value >= value) {
          max = value;

          while (++i < n) {
            // Compare the remaining values.
            if ((value = valueof(values[i], i, values)) != null && value > max) {
              max = value;
            }
          }
        }
      }
    }

    return max;
  };

  var mean = function (values, valueof) {
    var n = values.length,
        m = n,
        i = -1,
        value,
        sum = 0;

    if (valueof == null) {
      while (++i < n) {
        if (!isNaN(value = number(values[i]))) sum += value;else --m;
      }
    } else {
      while (++i < n) {
        if (!isNaN(value = number(valueof(values[i], i, values)))) sum += value;else --m;
      }
    }

    if (m) return sum / m;
  };

  var median = function (values, valueof) {
    var n = values.length,
        i = -1,
        value,
        numbers = [];

    if (valueof == null) {
      while (++i < n) {
        if (!isNaN(value = number(values[i]))) {
          numbers.push(value);
        }
      }
    } else {
      while (++i < n) {
        if (!isNaN(value = number(valueof(values[i], i, values)))) {
          numbers.push(value);
        }
      }
    }

    return quantile(numbers.sort(ascending), 0.5);
  };

  var merge = function (arrays) {
    var n = arrays.length,
        m,
        i = -1,
        j = 0,
        merged,
        array;

    while (++i < n) j += arrays[i].length;

    merged = new Array(j);

    while (--n >= 0) {
      array = arrays[n];
      m = array.length;

      while (--m >= 0) {
        merged[--j] = array[m];
      }
    }

    return merged;
  };

  var min = function (values, valueof) {
    var n = values.length,
        i = -1,
        value,
        min;

    if (valueof == null) {
      while (++i < n) {
        // Find the first comparable value.
        if ((value = values[i]) != null && value >= value) {
          min = value;

          while (++i < n) {
            // Compare the remaining values.
            if ((value = values[i]) != null && min > value) {
              min = value;
            }
          }
        }
      }
    } else {
      while (++i < n) {
        // Find the first comparable value.
        if ((value = valueof(values[i], i, values)) != null && value >= value) {
          min = value;

          while (++i < n) {
            // Compare the remaining values.
            if ((value = valueof(values[i], i, values)) != null && min > value) {
              min = value;
            }
          }
        }
      }
    }

    return min;
  };

  var permute = function (array, indexes) {
    var i = indexes.length,
        permutes = new Array(i);

    while (i--) permutes[i] = array[indexes[i]];

    return permutes;
  };

  var scan = function (values, compare) {
    if (!(n = values.length)) return;
    var n,
        i = 0,
        j = 0,
        xi,
        xj = values[j];
    if (compare == null) compare = ascending;

    while (++i < n) {
      if (compare(xi = values[i], xj) < 0 || compare(xj, xj) !== 0) {
        xj = xi, j = i;
      }
    }

    if (compare(xj, xj) === 0) return j;
  };

  var shuffle = function (array, i0, i1) {
    var m = (i1 == null ? array.length : i1) - (i0 = i0 == null ? 0 : +i0),
        t,
        i;

    while (m) {
      i = Math.random() * m-- | 0;
      t = array[m + i0];
      array[m + i0] = array[i + i0];
      array[i + i0] = t;
    }

    return array;
  };

  var sum = function (values, valueof) {
    var n = values.length,
        i = -1,
        value,
        sum = 0;

    if (valueof == null) {
      while (++i < n) {
        if (value = +values[i]) sum += value; // Note: zero and null are equivalent.
      }
    } else {
      while (++i < n) {
        if (value = +valueof(values[i], i, values)) sum += value;
      }
    }

    return sum;
  };

  var transpose = function (matrix) {
    if (!(n = matrix.length)) return [];

    for (var i = -1, m = min(matrix, length), transpose = new Array(m); ++i < m;) {
      for (var j = -1, n, row = transpose[i] = new Array(n); ++j < n;) {
        row[j] = matrix[j][i];
      }
    }

    return transpose;
  };

  function length(d) {
    return d.length;
  }

  var zip = function () {
    return transpose(arguments);
  };

  exports.bisect = bisectRight;
  exports.bisectRight = bisectRight;
  exports.bisectLeft = bisectLeft;
  exports.ascending = ascending;
  exports.bisector = bisector;
  exports.cross = cross;
  exports.descending = descending;
  exports.deviation = deviation;
  exports.extent = extent;
  exports.histogram = histogram;
  exports.thresholdFreedmanDiaconis = freedmanDiaconis;
  exports.thresholdScott = scott;
  exports.thresholdSturges = sturges;
  exports.max = max;
  exports.mean = mean;
  exports.median = median;
  exports.merge = merge;
  exports.min = min;
  exports.pairs = pairs;
  exports.permute = permute;
  exports.quantile = quantile;
  exports.range = range;
  exports.scan = scan;
  exports.shuffle = shuffle;
  exports.sum = sum;
  exports.ticks = ticks;
  exports.tickIncrement = tickIncrement;
  exports.tickStep = tickStep;
  exports.transpose = transpose;
  exports.variance = variance;
  exports.zip = zip;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const bisect = exports.bisect,
      bisectRight = exports.bisectRight,
      bisectLeft = exports.bisectLeft,
      ascending = exports.ascending,
      bisector = exports.bisector,
      cross = exports.cross,
      descending = exports.descending,
      deviation = exports.deviation,
      extent = exports.extent,
      histogram = exports.histogram,
      thresholdFreedmanDiaconis = exports.thresholdFreedmanDiaconis,
      thresholdScott = exports.thresholdScott,
      thresholdSturges = exports.thresholdSturges,
      max = exports.max,
      mean = exports.mean,
      median = exports.median,
      merge = exports.merge,
      min = exports.min,
      pairs = exports.pairs,
      permute = exports.permute,
      quantile = exports.quantile,
      range = exports.range,
      scan = exports.scan,
      shuffle = exports.shuffle,
      sum = exports.sum,
      ticks = exports.ticks,
      tickIncrement = exports.tickIncrement,
      tickStep = exports.tickStep,
      transpose = exports.transpose,
      variance = exports.variance,
      zip = exports.zip,
      __esModule = exports.__esModule;